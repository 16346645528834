import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import envo from "../../environments/environmentProd";
import h, { getLoginData } from "../../helpers/helpers";
import { list } from "./menuList";
import { list2 } from "./menuList2";
import { list3 } from "./menuList3";
import { list4 } from "./menuList4";
import { list5 } from "./menuList5";
import { list8 } from "./menuList8";
import { list9 } from "./menuList9";

import { connect } from "react-redux";
import { Route } from "react-router-dom";
import "./SideNav.scss";
import SideNavDisplay from "./SideNav.styled";
import { propTypes } from "react-image";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const apiUrl = envo.apiUrl;

function SideNav({ open, toggleSidenav, gamesCo }) {
  const {pathname} = useLocation();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [gamesCount, setGamesCount] = useState(0);
  //const [RoutLink, setRoutLink] = useState("/login");
  //const [test, settest] = useState("");

  const [userPhoto, setUserPhoto] = useState("");
  const [userName, setUserName] = useState("");

  /* set lists on different portals */
  var url = window.location.href;
  var urlKsa = window.location.href.includes("ksa");
  var urlEgy = window.location.href.includes("egypt");
  var urlEthio = window.location.href.includes("ethio");
  var urlGamezone = window.location.href.includes("ao.playit");
  var urlcompetition = window.location.hostname.includes("competition");

  var urlmtnng = window.location.hostname.includes("mtnng");
  var urlksa = window.location.hostname.includes("ksa");
  var urlgamezroid = window.location.hostname.includes("gamezroid");
  var urlramdan = window.location.hostname.includes("ramdank");
  var globalurl = window.location.hostname;
  var urlAfricell = window.location.hostname.includes("africell");
  var newgamezroid =
    urlgamezroid &&
    !globalurl.includes("asiacell") &&
    !globalurl.includes("jawwal") &&
    !globalurl.includes("zainiq") &&
    !globalurl.includes("ramdank");

  //  var newgamezroid = window.location.href.includes("local");

  var myList;
  var routerSignup = "/signup";
  var routerLink = "/login";
  //console.log("sidbar")

  if (urlksa) {
    routerSignup = "/signupksa";
  } else if (
    urlgamezroid &&
    !globalurl.includes("asiacell") &&
    !globalurl.includes("jawwal") &&
    !globalurl.includes("zainiq") &&
    !globalurl.includes("ramdank")
  ) {
    routerSignup = "/signup11";
    // routerSignup ="/Ramdanksignup"
  } else if (globalurl.includes("ramdank")) {
    routerSignup = "/Ramdanksignup";
  } else {
    routerSignup = "/signup";
  }

  if (urlcompetition) {
    routerLink = "/signup";
  }
  if (url.includes("zain-iq")) {
    myList = [...list2];
  }
  if (url.includes("jawwal")) {
    myList = [...list];
  } else if (globalurl.includes("asiacell")) {
    myList = [...list9];
  } else if (urlKsa || urlEgy) {
    myList = [...list3];
  } else if (urlEthio) {
    myList = [...list4];
  } else if (urlGamezone) {
    myList = [...list5];
  }
  // else if (newgamezroid)  { myList = [...list8] }
  else if (urlmtnng) {
    checkAuth();
    myList = [...list];
  } else {
    myList = [...list];
  }

  // if (urlmtnng) {
  //   checkAuth();
  //   myList = [...list]
  // }

  const [availableLinks, setAvailableLinks] = useState(myList);
  useEffect(() => {
    checkAuth();
  }, []);

  function notCompletedProfile() {
    const data = getLoginData();
    if (!data.email || !data.userName || !data.userPhoto) {
      return true;
    }
    return false;
  }

  async function checkAuth() {
   //console.log("checkAuth");
    const authorized = await client.auth();
   //console.log("auth", authorized);
    if (authorized) {
      const data = getLoginData();
     //console.log("user info", data);
      setUserName(data.userName);
      setUserPhoto(data.userPhoto);
      const count2 = await getGamesCount();
      setGamesCount(count2);
      return setIsLogged(true);
    }
    setUserName("");
    setUserPhoto("");
    return setIsLogged(false);
  }

  // async function checkAuth() {
  //  //console.log('checkAuth')
  //   const authorized = await client.auth();
  //   if (authorized) {
  //     setUserInfo(getLoginData());
  //     const count2 = await getGamesCount();
  //     setGamesCount(count2);
  //     return setIsLogged(true);
  //   }
  //   setUserInfo({});
  //   return setIsLogged(false);
  // }

  async function getGamesCount() {
    const options = {
      headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
    };
    const res = await axios.get(`${apiUrl}/playit/stats`, options);
    if (!res.data) return 0;

    return res.data.data.myGamesCount;
  }

  useEffect(() => {
    if (isLogged) {
     //console.log("isLogged");
      return setAvailableLinks(myList);
    } else {
     //console.log("isnotLogged");
      const filteredList = myList.filter((i) => !i.logged);
     //console.log(filteredList);
      var langItemIndex = filteredList.findIndex(
        (res) => res.url == "/change-language"
      );

      if (urlAfricell) {
        filteredList.splice(langItemIndex, 1);
      }
      return setAvailableLinks(filteredList);
    }
  }, [isLogged]);

  const closeNav = () => {
    toggleSidenav();
  };
  const changeActiveCat = () =>{
    let active_main = JSON.parse(localStorage.getItem('activeLocally')).main
    let active_sub = JSON.parse(localStorage.getItem('activeLocally')).main
    let active_category = JSON.parse(localStorage.getItem('activeLocally')).main
    // location 

    toggleSidenav();
    if(pathname=="/"){
      const obj = {
        main: active_main,
        sub: active_sub,
        category: "",
      };
      localStorage.setItem("activeLocally", JSON.stringify(obj));
  
      window.location.reload();
    }else{
      history.push("/");
    }

  };


  const listSection = availableLinks.map((i, k) => {
    if (i.text === "my_games") {
      //console.log('not filtered list')
      return (
        <Link
          to={i.url}
          className="listItem"
          key={`cmenuItem-${k}`}
          onClick={() => closeNav()}
        >
          <Trans>{i.text}</Trans>
          <div className="count">{gamesCo + gamesCount}</div>
        </Link>
      );
    }
    if (i.text === "side_nav.profile" && notCompletedProfile()) {
      return (
        <Link
          to={i.url}
          className="listItem notCompleted"
          key={`bmenuItem-${k}`}
          onClick={() => closeNav()}
        >
          <Trans>{i.text}</Trans>
          <div className="ellipse"></div>
        </Link>
      );
    }

    if (i.text === "Home") {
      return (
        <Link
          to={i.url}
          className="listItem home"
          key={`bmenuItem-${k}`}
          onClick={() => changeActiveCat()}
        >
          <Trans>{i.text}</Trans>
        </Link>
      );
    }

    return (
      <Link
        to={i.url}
        className="listItem"
        key={`amenuItem-${k}`}
        onClick={() => closeNav()}
      >
        <Trans>{i.text}</Trans>
      </Link>
    );
  });
  ////console.log('listSection',listSection);
  //   const Refresh = ({ path = '/' }) => (
  //     <Route
  //         path={path}
  //         component={({ history, location, match }) => {
  //             history.replace({
  //                 ...location,
  //                 pathname:location.pathname.substring(match.path.length)
  //             });
  //             return null;
  //         }}
  //     />
  // );

  return (
    <SideNavDisplay
      id="sideNav"
      open={open}
      isRTL={h.isRTL()}
      profileImg={userPhoto}
    >
      {/* <Refresh path="/refresh"/> */}
      <div className="side">
        <div className="close" onClick={() => closeNav()}>
          &times;
        </div>
        <div className="header">
          <div className="img">
            <div className="avatar" />
          </div>
          {isLogged ? (
            <div className="info">
              {false /*nocompleted*/ ? (
                <div>
                  <Trans>Hi There!</Trans>
                </div>
              ) : (
                <>
                  <div>{userName}</div>
                  <div>{localStorage.getItem("email")}</div>
                </>
              )}
            </div>
          ) : (
            // Anonymous
            <div className="info">
              <div>
                <Trans>Hi There!</Trans>
              </div>
              <div className="links">
                <Link to={routerLink}>
                  <Trans>sign_in</Trans>
                </Link>
                <Link to={routerSignup}>
                  <Trans>sign_up</Trans>
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className={urlramdan ? "list listcom" : "list"}>{listSection}</div>
      </div>

      <div className="rest" onClick={() => closeNav()} />
    </SideNavDisplay>
  );
}

/* Start Update State Use REDUX */
function mapStateToProps(state) {
  return { gamesCo: state.count };
}

function mapDispatchToProps(dispatch) {
  return {
    increase: () => dispatch({ type: "INCREASE" }),
    decrease: () => dispatch({ type: "DECREASE" }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SideNav));
