import React, { Component, Suspense } from "react";
import "./App.scss";
import h from "./helpers/helpers";
import { ethLogin } from "./operators/pages/EthSignupPage/services";
import { ncellLogin } from "./operators/pages/NcellSignupPage/services";
import { bhrainLogin } from "./operators/pages/VivaSignupPage/services";
import Routes from "./Routes";
import changeTheme from "./theming/themes";
import queryString from "query-string";
//import * as localLang from "./i18n/i18n";

class App extends Component {
  async componentDidMount() {
    //const tdzo = window.location.hostname.includes("tdzo");
    let url = window.location.href;
    if (url.includes("signup") && url.includes("dzo") && url.includes("?")){
      localStorage.setItem("i18nextLng", "fr");
    }

    //yousry lang
    const params = queryString.parse(window.location.search);
    if (params.lang) {
     //console.log("lang");
      localStorage.setItem("i18nextLng", params.lang);
      //localLang.checklocalLanguage();
    }
    rtlSetting();
    await setTheme();
    await autoLogin();
  }

  render() {
    return (
      <div className="App">
        <Suspense
          fallback={
            <div>
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          }
        >
          <Routes />
        </Suspense>
      </div>
    );
  }
}

// function clearBrowserCache() { const contextValue = useClearBrowserCache(); return null; }
async function setTheme() {
  //////////// check hostname
  const hostName = window.location.hostname;
  //const hostName = "aciq.gamezworld.mobi"; 
  let theme = h.getThemeByHostName(hostName);

  const countryCode = await h.getCountryCode();
  if (theme) {
   //console.log('11111111');
    return changeTheme(theme);
  }
  theme = h.getThemeByCountryCode(countryCode);
  if (theme) { 
   //console.log('2222222');
    return changeTheme(theme);
  }
 //console.log('3333333333');
  return changeTheme("default");
}
function rtlSetting() {
  if (localStorage.getItem("i18nextLng")) {
    const lang = localStorage.getItem("i18nextLng");

    //document.body.dir = lang === "en" ? "ltr" : "rtl";
    if (lang === "en" || lang === "fr" || lang === "pt" || lang === "indo") {
      document.body.dir = "ltr";
    } else {
      document.body.dir = "rtl";
    }
  }
}
async function autoLogin() {
  const hostname = document.location.host;
  if (hostname === "ethio.playit.mobi") {
    const res = await ethLogin();
    if (res) {
      // window.location.reload();
    }
  } else if (hostname === "ncell.playit.mobi") {
    const res = await ncellLogin();
    if (res) {
    }
  } else if (hostname === "ncell.playit.mobi") {
    const res = await bhrainLogin();
    if (res) {
    }
  }
}
export default App;
