
/* check if portal = zain-iq  to hidden login form use show stats prop*/


export const list2 = [
  {
    url: "/profile",
    text: "side_nav.profile",
    logged: true
  },
  {
    url: "/my-games",
    text: "my_games",
    logged: true
  },
  {
    url: "/",
    text: "Home"
  },
  {
    url: "/leaderboard",
    text: "side_nav.ranking"
  },
  {
    url: "/faqs",
    text: "side_nav.faq"
  },
  
  {
    url: "/tos",
    text: "terms_and_conditions"
  },
  {
    url: "/videoPlay",
    text: "side_nav.videos_tutorials"
  },
  {
    url: "/change-language",
    text: "side_nav.language"
  },
  {
    url: "/logout",
    text: "side_nav.logout",
    logged: true
  },
  // {
  //   url: "/payment",
  //   text: "payment_methods",
  //   logged: true
  // },
];
