import axios from 'axios';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import envo from "../../environments/environmentProd";
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import './TermsPage.scss';
import TermsPageDisplay from './TermsPage.styled';
const apiUrl = envo.apiUrl;

const globalurl = window.location.href
const urlgamezroid = window.location.href.includes('ramdan') && !globalurl.includes('asiacell') && !globalurl.includes('jawwal') && !globalurl.includes('zainiq')
class TermsPage extends Component {
	constructor() {
		super();
		this.state = {
			content: "",
			errors: false,
			errorMsg: "",
		};
	}


	componentDidMount() {
		var lang = localStorage.i18nextLng;
		if (window.location.href.includes('ao.playit') || window.location.href.includes('local')) {
			window.localStorage.setItem('operatorId', '467x6F5iHl');
			lang = "pt";
		}
		if (window.location.href.includes('libya.playit')) {
			window.localStorage.setItem('operatorId', 'CcoIV1eQFA');
			//lang = "ar";
		}
		const operatorId = localStorage.operatorId;
		//console.log(localStorage,window.localStorage)
		
		this.getTerms(operatorId, lang);
	}
	async getTerms(operatorId, lang) {
		const params = {
			"operator_id": operatorId,
			"locale": lang
		};
		try {
			const res = await axios.get(`${apiUrl}/playit/terms`, { params });
			if (!res.data) return false;
			const data = res.data;
			if (data.status !== "success") {
				return false;
			}
			if (data.data) {
				console.log(data.data.term)
				return this.setState({
					content: data.data.term
				});
			}
		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
			console.log(err);
			return false;
		}
	}

	createMarkup() {
		return { __html: this.state.content };
	}

	render() {
		const { errors, errorMsg, content } = this.state;
		return (
			<>
				<TermsPageDisplay >
					<div className="title" style={{color : urlgamezroid? '#0a2631eb': ''}}><Trans>terms_and_conditions</Trans></div>
					{urlgamezroid ?
						<div className="content">
							<Trans>terms</Trans>
						</div>
						:
						<div className="content" dangerouslySetInnerHTML={this.createMarkup()} />
					}
					{errors ? <FloatingAlert type="error">{errorMsg} <a className="reload" href=" "><Trans>try_again</Trans></a></FloatingAlert> : ""}
				</TermsPageDisplay>
			</>
		)
	}
}
export default withTranslation()(TermsPage)